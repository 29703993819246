import { FETCH_COMPANIES, FETCH_COMPANY_BREAKDOWNS } from "./actions"
import { PaginatedData } from "../../types/root"
import { CompaniesActionProps, CompaniesProps, Company, CompanyBreakDown } from "types/companies"

export const initialCompanyState: CompaniesProps = {
  paginatedCompanyList: new PaginatedData<Company>(),
  paginatedCompanyBreakdownList: new PaginatedData<CompanyBreakDown>()
}

const companies = (state = initialCompanyState, action: CompaniesActionProps) => {
  switch (action.type) {
    case FETCH_COMPANIES: {
      const paginatedCompanyList = (action.payload?.paginatedCompanyList as PaginatedData<Company>) || new PaginatedData<Company>()
      return {
        ...state,
        paginatedCompanyList
      }
    }
    case FETCH_COMPANY_BREAKDOWNS: {
      const paginatedCompanyBreakdownList = (action.payload?.paginatedCompanyBreakdownList as PaginatedData<CompanyBreakDown>) || new PaginatedData<CompanyBreakDown>()
      return {
        ...state,
        paginatedCompanyBreakdownList
      }
    }
    default: {
      return { ...state }
    }
  }
}

export default companies
