import Routes from "routes"
import ThemeCustomization from "themes"
import Locales from "components/Locales"
import RTLLayout from "components/RTLLayout"
import ScrollTop from "components/ScrollTop"
import Snackbar from "components/@extended/Snackbar"
import Notistack from "components/third-party/Notistack"
import { JWTProvider as AuthProvider } from "contexts/JWTContext"
import { TracksProvider } from "./contexts/TracksContext"
import { ArtistsProvider } from "./contexts/ArtistsContext"
import { CompaniesProvider } from "contexts/CompaniesContext"
import { ReportsProvider } from "./contexts/ReportsContext"
import { PlaylistsProvider } from "./contexts/PlaylistContext"
import { AlbumsProvider } from "./contexts/AlbumsContext"
import { SpotifyArtistRequestProviderProvider } from "contexts/SpotifyArtistRequestContext"
import { SystemsProvider } from "contexts/SystemContext"
import { UsersProvider } from "contexts/UsersContext"
// import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext'
// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext'
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context'

const App = () => {
  /*const [loading, setLoading] = useState<boolean>(true)

  if (loading) return <Loader />*/

  return (
    <ThemeCustomization>
      <RTLLayout>
        <Locales>
          <ScrollTop>
            <AuthProvider>
              <UsersProvider>
                <ArtistsProvider>
                  <SpotifyArtistRequestProviderProvider>
                    <AlbumsProvider>
                      <PlaylistsProvider>
                        <SystemsProvider>
                          <TracksProvider>
                            <CompaniesProvider>
                              <ReportsProvider>
                                <Notistack>
                                  <Routes />
                                  <Snackbar />
                                </Notistack>
                              </ReportsProvider>
                            </CompaniesProvider>
                          </TracksProvider>
                        </SystemsProvider>
                      </PlaylistsProvider>
                    </AlbumsProvider>
                  </SpotifyArtistRequestProviderProvider>
                </ArtistsProvider>
              </UsersProvider>
            </AuthProvider>
          </ScrollTop>
        </Locales>
      </RTLLayout>
    </ThemeCustomization>
  )
}

export default App
