// material-ui
import { Theme } from "@mui/material/styles"

// ==============================|| OVERRIDES - LIST ITEM ICON ||============================== //

export default function ListItemIcon(theme: Theme) {
  return {
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 28,
          color: theme.palette.secondary.main
        }
      }
    }
  }
}
