import { FETCH_ALBUMS } from "./actions"
import { PaginatedData } from "../../types/root"
import { Album, AlbumsActionProps, AlbumsProps } from "../../types/albums"

export const initialAlbumsState: AlbumsProps = {
  paginatedAlbums: new PaginatedData<Album>()
}

const albums = (state = initialAlbumsState, action: AlbumsActionProps) => {
  switch (action.type) {
    case FETCH_ALBUMS: {
      const paginatedAlbums =
        (action.payload?.paginatedAlbums as PaginatedData<Album>) || new PaginatedData<Album>()
      return {
        ...state,
        paginatedAlbums
      }
    }
    default: {
      return { ...state }
    }
  }
}

export default albums
