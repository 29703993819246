import { FETCH_USERS } from "./actions"
import { PaginatedData } from "../../types/root"
import { User, UsersActionProps, UsersProps } from "../../types/users"

export const initialUsersState: UsersProps = {
  paginatedUsers: new PaginatedData<User>()
}

const users = (state = initialUsersState, action: UsersActionProps) => {
  switch (action.type) {
    case FETCH_USERS: {
      const paginatedUsers = (action.payload?.paginatedUsers as PaginatedData<User>) || new PaginatedData<User>()
      return {
        ...state,
        paginatedUsers
      }
    }
    default: {
      return { ...state }
    }
  }
}

export default users
