import { createContext, ReactElement, useReducer } from "react"
import usersReducer, { initialUsersState } from "../store/reducers/users"
import axios from "utils/axios"
import { FETCH_USERS } from "../store/reducers/actions"
import { mapPaginatedDataToCamelCase, mapToCamelCase } from "../utils/caseTransformation"
import { PaginatedData } from "types/root"
import { getFilterText } from "../utils"
import { User, UsersContextType } from "../types/users"

const UsersContext = createContext<UsersContextType | null>(null)

export const UsersProvider = ({ children }: { children: ReactElement }) => {
  const [state, dispatch] = useReducer(usersReducer, initialUsersState)

  const fetchUsers = async (limit: number, offset: number, filter?: any, orderBy?: string) => {
    const response = await axios(
      `/users/?limit=${limit}&offset=${offset}${getFilterText(filter)}${orderBy ? `&order_by=${orderBy}` : ""}`,
      { method: "get" }
    )

    dispatch({
      type: FETCH_USERS,
      payload: { ...state, paginatedUsers: mapPaginatedDataToCamelCase<PaginatedData<User>>(response.data) }
    })
  }

  return <UsersContext.Provider value={{ ...state, fetchUsers }}>{children}</UsersContext.Provider>
}

export default UsersContext
