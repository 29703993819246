import { Link } from "react-router-dom"
import { To } from "history"

// material-ui
import { ButtonBase } from "@mui/material"
import { SxProps } from "@mui/system"

// project-imports
import Logo from "./LogoMain"
import LogoIcon from "./LogoIcon"

// ==============================|| MAIN LOGO ||============================== //

interface Props {
  reverse?: boolean
  isIcon?: boolean
  sx?: SxProps
  to?: To
  logoText: string
}

const LogoSection = ({ reverse, isIcon, sx, to, logoText }: Props) => (
  <ButtonBase disableRipple component={Link} to={to?.toString() || "/"} sx={sx}>
    {isIcon ? <LogoIcon /> : <Logo reverse={reverse} text={logoText} />}
  </ButtonBase>
)

export default LogoSection
