import { FormattedMessage } from "react-intl"
import {
  DirectboxReceive,
  Heart,
  Music,
  Profile2User,
  VolumeSlash,
  Buildings,
  Layer,
  Graph,
  Money,
  HuobiToken,
  MusicPlaylist,
  MusicSquare,
  People
} from "iconsax-react"
import { NavItemType } from "types/menu"

const icons = {
  hotlist: HuobiToken,
  playlist: MusicPlaylist,
  tracks: Music,
  releases: MusicSquare,
  artists: Profile2User,
  inbounds: DirectboxReceive,
  shortlist: Heart,
  blacklist: VolumeSlash,
  companyBreakdown: Layer,
  offersReports: Graph,
  company: Buildings,
  confirmedOffers: Money,
  spotifyArtistRequest: People
}

const scoutingNavItems: NavItemType = {
  id: "navigation",
  title: <FormattedMessage id="navigation" />,
  type: "group",
  children: [
    {
      id: "playlist",
      title: <FormattedMessage id="playlist" />,
      type: "item",
      url: "/playlist",
      icon: icons.playlist,
      admin: true
    },
    {
      id: "tracks",
      title: <FormattedMessage id="tracks" />,
      type: "item",
      url: "/tracks",
      icon: icons.tracks,
      admin: false
    },
    {
      id: "artists",
      title: <FormattedMessage id="artists" />,
      type: "item",
      icon: icons.artists,
      url: "/artists",
      admin: false
    },
    {
      id: "releases",
      title: <FormattedMessage id="releases" />,
      type: "item",
      icon: icons.releases,
      url: "/releases",
      admin: false
    },
    {
      id: "blacklist",
      title: <FormattedMessage id="blacklist" />,
      type: "item",
      icon: icons.blacklist,
      url: "/blacklist",
      admin: true
    },
    {
      id: "offersReports",
      title: <FormattedMessage id="offersReports" />,
      type: "item",
      url: "/offers-reports",
      icon: icons.offersReports,
      admin: false
    },
    {
      id: "companyBreakdown",
      title: <FormattedMessage id="companyBreakdown" />,
      type: "item",
      url: "/company-breakdown",
      icon: icons.companyBreakdown,
      admin: true
    },
    {
      id: "company",
      title: <FormattedMessage id="company" />,
      type: "item",
      url: "/company",
      icon: icons.company,
      admin: true
    },
    {
      id: "spotifyArtistRequest",
      title: <FormattedMessage id="spotifyArtistRequest" />,
      type: "item",
      url: "/spotify-artist-request",
      icon: icons.spotifyArtistRequest,
      admin: false
    },
    {
      id: "confirmedOffers",
      title: <FormattedMessage id="confirmedOffers" />,
      type: "item",
      url: "/confirmed-offers",
      icon: icons.confirmedOffers,
      admin: false
    }
  ]
}

export default scoutingNavItems
