import { createContext, useEffect, useReducer, ReactElement } from "react"
import { Chance } from "chance"
import jwtDecode from "jwt-decode"
import { LOGIN, LOGOUT } from "store/reducers/actions"
import authReducer from "store/reducers/auth"
import Loader from "components/Loader"
import axios from "utils/axios"
import { KeyedObject } from "types/root"
import { AuthProps, JWTContextType, UserProfile } from "types/auth"

const chance = new Chance()

// constant
const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  scoutingAccess: false,
  offersAccess: false,
  adminAccess: false,
  user: null
}

export const setSession = (serviceToken?: string | null) => {
  if (serviceToken) {
    localStorage.setItem("serviceToken", serviceToken)
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`
  } else {
    localStorage.removeItem("serviceToken")
    delete axios.defaults.headers.common.Authorization
  }
}

const verifyToken = async (serviceToken: string) => {
  if (!serviceToken) {
    return false
  }
  const decoded: KeyedObject = jwtDecode(serviceToken)

  /**
   * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
   */

  let isValid = decoded.exp > Date.now() / 1000

  if (isValid) {
    setSession(serviceToken)
  } else {
    const tokenResponse = await axios.post("/refresh/", {}, { withCredentials: true })
    if (tokenResponse?.data?.token) {
      const token = tokenResponse.data.token
      setSession(token)
      isValid = true
    } else {
      setSession(null)
    }
  }

  return isValid
}

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext<JWTContextType | null>(null)

export const JWTProvider = ({ children }: { children: ReactElement }) => {
  const [state, dispatch] = useReducer(authReducer, initialState)

  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = localStorage.getItem("serviceToken")

        if (serviceToken) {
          const isTokenValid = await verifyToken(serviceToken)
          if (!isTokenValid) {
            dispatch({
              type: LOGOUT
            })
            return
          }

          const response = await axios.get("/me/")
          const user = response.data as UserProfile

          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              scoutingAccess: user.scouting_access,
              offersAccess: user.offers_access,
              adminAccess: user.admin_access,
              user
            }
          })
        } else {
          dispatch({
            type: LOGOUT
          })
        }
      } catch (err) {
        console.error(err)
        dispatch({
          type: LOGOUT
        })
      }
    }

    init().then()
  }, [])

  const login = async (username: string, password: string) => {
    const response = await axios.post("/login/", { username, password }, { withCredentials: true })

    const { serviceToken, user } = { serviceToken: response.data.token, user: response.data as UserProfile }
    setSession(serviceToken)
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        scoutingAccess: user.scouting_access,
        offersAccess: user.offers_access,
        adminAccess: user.admin_access,
        user
      }
    })
  }

  const offersAuthenticate = async (username: string, password: string, ipAddress: string, codeEnabled: boolean) => {
    const response = await axios.post("/offers/authenticate/", {
      username,
      password,
      ip_address: ipAddress,
      code_enabled: codeEnabled
    }, { withCredentials: true })
    if (!response.data.success) {
      return response.data
    }

    const { serviceToken, user } = { serviceToken: response.data.user.token, user: response.data.user as UserProfile }
    setSession(serviceToken)
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        scoutingAccess: user.scouting_access,
        offersAccess: user.offers_access,
        adminAccess: user.admin_access,
        user
      }
    })

    return response.data
  }

  const offersCodeValidation = async (username: string, password: string, name: string, code: string, country: string) => {
    const response = await axios.post("/code-validation/", { username, password, name, code, country }, { withCredentials: true })
    if (!response.data.success) {
      return false
    }

    const { serviceToken, user } = { serviceToken: response.data.user.token, user: response.data.user as UserProfile }
    setSession(serviceToken)
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        scoutingAccess: user.scouting_access,
        offersAccess: user.offers_access,
        adminAccess: user.admin_access,
        user
      }
    })

    return true
  }

  const loginSpotify = async (code: string) => {
    const response = await axios.post(`/spotify/?code=${code}`, null, {
      withCredentials: true
    })

    const { serviceToken, user } = { serviceToken: response.data.token, user: response.data as UserProfile }
    setSession(serviceToken)
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        scoutingAccess: user.scouting_access,
        offersAccess: user.offers_access,
        adminAccess: user.admin_access,
        user
      }
    })
  }

  const register = async (email: string, password: string, firstName: string, lastName: string) => {
    // todo: this flow need to be recode as it not verified
    const id = chance.bb_pin()
    const response = await axios.post("/api/account/register", {
      id,
      email,
      password,
      firstName,
      lastName
    })
    let users = response.data

    if (window.localStorage.getItem("users") !== undefined && window.localStorage.getItem("users") !== null) {
      const localUsers = window.localStorage.getItem("users")
      users = [
        ...JSON.parse(localUsers!),
        {
          id,
          email,
          password,
          name: `${firstName} ${lastName}`
        }
      ]
    }

    window.localStorage.setItem("users", JSON.stringify(users))
  }

  const logout = () => {
    setSession(null)
    dispatch({ type: LOGOUT })
  }

  const resetPassword = async (email: string) => {
  }

  const updateProfile = () => {
  }

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />
  }

  return (
    <JWTContext.Provider value={{
      ...state,
      login,
      loginSpotify,
      offersAuthenticate,
      offersCodeValidation,
      logout,
      register,
      resetPassword,
      updateProfile
    }}>
      {children}
    </JWTContext.Provider>
  )
}

export default JWTContext
