import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"

// third-party
import { Provider as ReduxProvider } from "react-redux"

// classNames
import 'tailwindcss/tailwind.css'

// fonts
import "assets/fonts/inter/inter.css"

// scroll bar
import "simplebar/dist/simplebar.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

// apex-chart
import "assets/third-party/apex-chart.css"
import "assets/third-party/react-table.css"

// project-imports
import App from "./App"
import { store } from "store"
import { ConfigProvider } from "contexts/ConfigContext"
import reportWebVitals from "./reportWebVitals"

const container = document.getElementById("root")
const root = createRoot(container!)

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

root.render(
  <ReduxProvider store={store}>
    <ConfigProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ConfigProvider>
  </ReduxProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
