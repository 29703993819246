import { useEffect, ReactNode } from "react"
import { CacheProvider } from "@emotion/react"
import createCache from "@emotion/cache"
import useConfig from "hooks/useConfig"
import { ThemeDirection } from "types/config"

interface Props {
  children: ReactNode
}

const RTLLayout = ({ children }: Props) => {
  const { themeDirection } = useConfig()

  useEffect(() => {
    document.dir = themeDirection
  }, [themeDirection])

  const cacheRtl = createCache({
    key: themeDirection === ThemeDirection.RTL ? "rtl" : "css",
    prepend: true,
    stylisPlugins: []
  })

  return <CacheProvider value={cacheRtl}>{children}</CacheProvider>
}

export default RTLLayout
