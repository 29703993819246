import { createContext, ReactElement, useReducer } from "react"
import albumsReducer, { initialAlbumsState } from "../store/reducers/albums"
import axios from "utils/axios"
import { FETCH_ALBUMS } from "../store/reducers/actions"
import { mapPaginatedDataToCamelCase } from "../utils/caseTransformation"
import { PaginatedData } from "types/root"
import { getFilterText } from "../utils"
import { Album, AlbumsContextType } from "../types/albums"

const AlbumsContext = createContext<AlbumsContextType | null>(null)

export const AlbumsProvider = ({ children }: { children: ReactElement }) => {
  const [state, dispatch] = useReducer(albumsReducer, initialAlbumsState)

  const fetchAlbums = async (limit: number, offset: number, filter?: any, orderBy?: string) => {
    const response = await axios(
      `/albums/?limit=${limit}&offset=${offset}${getFilterText(filter)}${orderBy ? `&order_by=${orderBy}` : ""}`,
      { method: "get" }
    )

    dispatch({
      type: FETCH_ALBUMS,
      payload: { ...state, paginatedAlbums: mapPaginatedDataToCamelCase<PaginatedData<Album>>(response.data) }
    })
  }

  return <AlbumsContext.Provider
    value={{ ...state, fetchAlbums }}>{children}</AlbumsContext.Provider>
}

export default AlbumsContext
