import { FETCH_ALLOWED_OFFER_LINKS, SAVE_ALLOWED_OFFER_LINKS } from "./actions"
import { PaginatedData } from "../../types/root"
import { AllowedOffersLink, SystemActionProps, SystemProps } from "../../types/system"

export const initialSystemState: SystemProps = {
  paginatedAllowedOffersLinks: new PaginatedData<AllowedOffersLink>()
}

const systems = (state = initialSystemState, action: SystemActionProps) => {
  switch (action.type) {
    case FETCH_ALLOWED_OFFER_LINKS: {
      const paginatedAllowedOffersLinks =
        (action.payload?.paginatedAllowedOffersLinks as PaginatedData<AllowedOffersLink>) ||
        new PaginatedData<AllowedOffersLink>()
      return {
        ...state,
        paginatedAllowedOffersLinks
      }
    }
    case SAVE_ALLOWED_OFFER_LINKS: {
      const paginatedAllowedOffersLinks =
        (action.payload?.paginatedAllowedOffersLinks as PaginatedData<AllowedOffersLink>) ||
        new PaginatedData<AllowedOffersLink>()
      return {
        ...state,
        paginatedAllowedOffersLinks
      }
    }
    default: {
      return { ...state }
    }
  }
}

export default systems
