import { FETCH_PLAYLIST, FETCH_PLAYLISTS, UPDATE_PLAYLISTS_HOT_LIST } from "./actions"
import { PaginatedData } from "../../types/root"
import { Playlist, PlaylistsActionProps, PlaylistsProps } from "../../types/playlists"

export const initialPlaylistsState: PlaylistsProps = {
  paginatedPlaylists: new PaginatedData<Playlist>(),
  selectedPlaylist: undefined
}

const playlists = (state = initialPlaylistsState, action: PlaylistsActionProps) => {
  switch (action.type) {
    case FETCH_PLAYLISTS: {
      const paginatedPlaylists =
        (action.payload?.paginatedPlaylists as PaginatedData<Playlist>) || new PaginatedData<Playlist>()
      return {
        ...state,
        paginatedPlaylists
      }
    }
    case FETCH_PLAYLIST: {
      return {
        ...state,
        selectedPlaylist: action.payload?.selectedPlaylist
      }
    }
    case UPDATE_PLAYLISTS_HOT_LIST: {
      const paginatedPlaylists =
        (action.payload?.paginatedPlaylists as PaginatedData<Playlist>) || new PaginatedData<Playlist>()
      return {
        ...state,
        paginatedPlaylists
      }
    }
    default: {
      return { ...state }
    }
  }
}

export default playlists
