import { FETCH_ARTIST_SOCIAL_LINKS, FETCH_ARTISTS } from "./actions"
import { PaginatedData } from "../../types/root"
import { Artist, ArtistsActionProps, ArtistSocialLink, ArtistsProps } from "../../types/artists"

export const initialArtistsState: ArtistsProps = {
  paginatedArtists: new PaginatedData<Artist>(),
  artistSocialLinks: {}
}

const artists = (state = initialArtistsState, action: ArtistsActionProps) => {
  switch (action.type) {
    case FETCH_ARTISTS: {
      const paginatedArtists =
        (action.payload?.paginatedArtists as PaginatedData<Artist>) || new PaginatedData<Artist>()
      return {
        ...state,
        paginatedArtists
      }
    }
    case FETCH_ARTIST_SOCIAL_LINKS: {
      const artistSocialLinks =
        (action.payload?.artistSocialLinks as ArtistSocialLink) || {}
      return {
        ...state,
        artistSocialLinks
      }
    }
    default: {
      return { ...state }
    }
  }
}

export default artists
