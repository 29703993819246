// material-ui
import { Theme } from "@mui/material/styles"

// ==============================|| OVERRIDES - DIALOG CONTENT TEXT ||============================== //

export default function DialogContentText(theme: Theme) {
  return {
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          fontSize: "0.875rem",
          color: theme.palette.text.primary
        }
      }
    }
  }
}
