import { useEffect, useLayoutEffect, useState } from "react"

// material-ui
import { useTheme } from "@mui/material/styles"
import { Box, Typography, useMediaQuery } from "@mui/material"

// project-imports
import NavGroup from "./NavGroup"
import menuItem from "menu-items/scouting"

import { useSelector } from "store"
import useConfig from "hooks/useConfig"
import { HORIZONTAL_MAX_ITEM } from "config"

// types
import { NavItemType } from "types/menu"
import { MenuOrientation } from "types/config"
import axios from "utils/axios"
import { EntityTotalReport } from "types/system"
import { mapToCamelCase } from "utils/caseTransformation"
import useAuth from "hooks/useAuth"

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const { user } = useAuth()

  const theme = useTheme()

  const downLG = useMediaQuery(theme.breakpoints.down("lg"))

  const { menuOrientation } = useConfig()
  const { drawerOpen } = useSelector((state) => state.menu)

  const [selectedItems, setSelectedItems] = useState<string | undefined>("")
  const [selectedLevel, setSelectedLevel] = useState<number>(0)
  const [menuItems, setMenuItems] = useState<{ items: NavItemType[] }>({ items: [] })
  const [entityTotalReport, setEntityTotalReport] = useState<EntityTotalReport>()

  useLayoutEffect(() => {
    const filteredMenuItems =
      menuItem.items[0]?.children?.filter((item) => {
        return !item.admin || (item.admin && user?.admin_access)
      }) || []

    setMenuItems({ ...menuItem, items: [{ ...menuItem.items[0], children: filteredMenuItems }] })
    // eslint-disable-next-line
  }, [menuItem])

  useEffect(() => {
    if (!user?.admin_access) {
      return
    }

    axios.get(`/system/entity-totals/report/`).then((resp) => {
      if (resp.data.result) {
        //@ts-ignore
        setEntityTotalReport(mapToCamelCase(resp.data.result))
      }
    })
  }, [])

  const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG

  const lastItem = isHorizontal ? HORIZONTAL_MAX_ITEM : null
  let lastItemIndex = menuItems.items.length - 1
  let remItems: NavItemType[] = []
  let lastItemId: string

  if (lastItem && lastItem < menuItems.items.length) {
    lastItemId = menuItems.items[lastItem - 1].id!
    lastItemIndex = lastItem - 1
    remItems = menuItems.items.slice(lastItem - 1, menuItems.items.length).map((item) => ({
      title: item.title,
      elements: item.children,
      icon: item.icon
    }))
  }

  const navGroups = menuItems.items.slice(0, lastItemIndex + 1).map((item) => {
    switch (item.type) {
      case "group":
        return (
          <NavGroup
            key={item.id}
            setSelectedItems={setSelectedItems}
            setSelectedLevel={setSelectedLevel}
            selectedLevel={selectedLevel}
            selectedItems={selectedItems}
            lastItem={lastItem!}
            remItems={remItems}
            lastItemId={lastItemId}
            item={item}
            entityTotalReport={entityTotalReport}
          />
        )
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        )
    }
  })
  return (
    <Box
      sx={{
        pt: drawerOpen ? (isHorizontal ? 0 : 2) : 0,
        "& > ul:first-of-type": { mt: 0 },
        display: isHorizontal ? { xs: "block", lg: "flex" } : "block"
      }}
    >
      {navGroups}
    </Box>
  )
}

export default Navigation
