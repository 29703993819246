import { FETCH_CONFIRMED_OFFERS } from "./actions"
import { PaginatedData } from "../../types/root"
import { ConfirmedOffer, ReportsActionProps, ReportsProps } from "../../types/reports"

export const initialReportsState: ReportsProps = {
  paginatedConfirmedOfferList: new PaginatedData<ConfirmedOffer>()
}

const reports = (state = initialReportsState, action: ReportsActionProps) => {
  switch (action.type) {
    case FETCH_CONFIRMED_OFFERS: {
      const paginatedConfirmedOfferList = (action.payload?.paginatedConfirmedOfferList as PaginatedData<ConfirmedOffer>) || new PaginatedData<ConfirmedOffer>()
      return {
        ...state,
        paginatedConfirmedOfferList
      }
    }
    default: {
      return { ...state }
    }
  }
}

export default reports
