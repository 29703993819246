import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"

// project-imports
import useAuth from "hooks/useAuth"

// types
import { GuardProps } from "types/auth"

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }: GuardProps) => {
  const { isLoggedIn, scoutingAccess } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (!isLoggedIn) {
      navigate(`/`, {
        state: {
          from: location.pathname
        },
        replace: true
      })
    } else {
      if (!scoutingAccess) {
        navigate("/", {
          state: {
            from: ""
          },
          replace: true
        })
      }
    }
  }, [isLoggedIn, navigate, location])

  return children
}

export default AuthGuard
