import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"

// project-imports
import { SCOUTING_DEFAULT_PATH } from "config"
import useAuth from "hooks/useAuth"

// types
import { GuardProps } from "types/auth"

// ==============================|| GUEST GUARD ||============================== //

const GuestGuard = ({ children }: GuardProps) => {
  const { isLoggedIn, scoutingAccess, logout } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (isLoggedIn && scoutingAccess) {
      navigate(location?.state?.from ? location?.state?.from : SCOUTING_DEFAULT_PATH, {
        state: {
          from: ""
        },
        replace: true
      })
    } else if (isLoggedIn && !scoutingAccess) {
      logout()
    }
  }, [isLoggedIn, navigate, location])

  return children
}

export default GuestGuard
