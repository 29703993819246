import { Typography } from "@mui/material"
import { Stack } from "@mui/system"
import { KLogo } from "kahuna-base-react-components"

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg'
 * import logo from 'assets/images/logo.svg'
 *
 */

const LogoMain = ({ reverse, text, ...others }: { reverse?: boolean; text: string }) => {
  //const theme = useTheme()
  return (
    <Stack>
      <div style={{ width: 225 }} className={"flex justify-center"}>
        <KLogo
          logoColor={"white"}
          width={50}
          borderRadius={5}
          primaryTextVisible={true}
          primaryTextWidth={120}
          primaryTextHeight={100}
        />
      </div>
    </Stack>
  )
}

export default LogoMain
