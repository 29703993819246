import { FETCH_BLACKLIST, FETCH_TRACKS } from "./actions"
import { BlacklistItem, Track, TracksActionProps, TracksProps } from "../../types/tracks"
import { PaginatedData } from "../../types/root"

export const initialTracksState: TracksProps = {
  paginatedTracks: new PaginatedData<Track>(),
  paginatedBlacklist: new PaginatedData<BlacklistItem>()
}

const tracks = (state = initialTracksState, action: TracksActionProps) => {
  switch (action.type) {
    case FETCH_TRACKS: {
      const paginatedTracks = (action.payload?.paginatedTracks as PaginatedData<Track>) || new PaginatedData<Track>()
      return {
        ...state,
        paginatedTracks
      }
    }
    case FETCH_BLACKLIST: {
      const paginatedBlacklist =
        (action.payload?.paginatedBlacklist as PaginatedData<BlacklistItem>) || new PaginatedData<BlacklistItem>()
      return {
        ...state,
        paginatedBlacklist
      }
    }
    default: {
      return { ...state }
    }
  }
}

export default tracks
