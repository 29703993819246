import { createContext, ReactElement, useReducer } from "react"
import axios from "utils/axios"
import { FETCH_PLAYLIST, FETCH_PLAYLISTS, UPDATE_PLAYLISTS_HOT_LIST } from "../store/reducers/actions"
import { mapPaginatedDataToCamelCase, mapToCamelCase } from "../utils/caseTransformation"
import { PaginatedData } from "types/root"
import { getFilterText } from "../utils"
import { Playlist, PlaylistsContextType } from "../types/playlists"
import playlistsReducer, { initialPlaylistsState } from "../store/reducers/playlists"

const PlaylistContext = createContext<PlaylistsContextType | null>(null)

export const PlaylistsProvider = ({ children }: { children: ReactElement }) => {
  const [state, dispatch] = useReducer(playlistsReducer, initialPlaylistsState)

  const fetchPlaylists = async (limit: number, offset: number, filter?: any, orderBy?: string) => {
    const response = await axios(
      `/playlist/?limit=${limit}&offset=${offset}${getFilterText(filter)}${orderBy ? `&order_by=${orderBy}` : ""}`,
      { method: "get" }
    )

    dispatch({
      type: FETCH_PLAYLISTS,
      payload: { ...state, paginatedPlaylists: mapPaginatedDataToCamelCase<PaginatedData<Playlist>>(response.data) }
    })
  }

  const fetchPlaylist = async (id: string) => {
    const response = await axios(
      `/playlist/${id}/`,
      { method: "get" }
    )
    const selectedPlaylist = mapToCamelCase(response.data)
    dispatch({
      type: FETCH_PLAYLIST,
      //@ts-ignore
      payload: { ...state, selectedPlaylist }
    })
  }


  const updateHotlist = async (id: number, value: boolean, isList: boolean) => {
    const response = await axios(
      `/playlist/update_hotlist/`,
      { method: "put", data: { id, hot_list: value } }
    )
    if (!response.data.success) {
      return
    }

    if (isList) {
      const updatedList = state.paginatedPlaylists.results.map((element) => {
        if (element.id === id) {
          element.hotList = value
        }
        return element
      })

      dispatch({
        type: UPDATE_PLAYLISTS_HOT_LIST,
        payload: { ...state, paginatedPlaylists: { ...state.paginatedPlaylists, results: updatedList } }
      })
    } else {
      dispatch({
        type: FETCH_PLAYLIST,
        //@ts-ignore
        payload: { ...state, selectedPlaylist: { ...state.selectedPlaylist, hotList: value } }
      })
    }
  }

  const addPlaylist = async (title: string, playlistId: string) => {
    const response = await axios(
      `/playlist/add/`,
      { method: "post", data: { title, playlist_id: playlistId } }
    )
    if (!response.data.entity.id) {
      return false
    }
    const addedPlaylist = response.data.entity
    const updatedList = [
      ...state.paginatedPlaylists.results, {
        created: addedPlaylist.created,
        updated: addedPlaylist.updated,
        id: addedPlaylist.id,
        hotList: false,
        title,
        playlistId,
        description: addedPlaylist.description,
        category: addedPlaylist.category
      }
    ]

    dispatch({
      type: UPDATE_PLAYLISTS_HOT_LIST,
      payload: { ...state, paginatedPlaylists: { ...state.paginatedPlaylists, results: updatedList } }
    })

    return true
  }

  return <PlaylistContext.Provider
    value={{
      ...state,
      fetchPlaylists,
      updateHotlist,
      addPlaylist,
      fetchPlaylist
    }}>{children}</PlaylistContext.Provider>
}

export default PlaylistContext
