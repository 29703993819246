import { FETCH_SPOTIFY_ARTIST_REQUESTS } from "./actions"
import { PaginatedData } from "../../types/root"
import {
  SpotifyArtistRequest,
  SpotifyArtistRequestActionProps,
  SpotifyArtistRequestProps
} from "../../types/spotifyArtistRequest"

export const initialSpotifyArtistRequestState: SpotifyArtistRequestProps = {
  paginatedSpotifyArtistRequests: new PaginatedData<SpotifyArtistRequest>()
}

const spotifyArtistRequests = (state = initialSpotifyArtistRequestState, action: SpotifyArtistRequestActionProps) => {
  switch (action.type) {
    case FETCH_SPOTIFY_ARTIST_REQUESTS: {
      const paginatedSpotifyArtistRequests =
        (action.payload?.paginatedSpotifyArtistRequests as PaginatedData<SpotifyArtistRequest>) ||
        new PaginatedData<SpotifyArtistRequest>()
      return {
        ...state,
        paginatedSpotifyArtistRequests
      }
    }
    default: {
      return { ...state }
    }
  }
}

export default spotifyArtistRequests
