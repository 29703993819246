import { createContext, ReactElement, useReducer } from "react"
import artistsReducer, { initialArtistsState } from "../store/reducers/artists"
import axios from "utils/axios"
import { FETCH_ARTIST_SOCIAL_LINKS, FETCH_ARTISTS } from "../store/reducers/actions"
import { mapPaginatedDataToCamelCase, mapToCamelCase } from "../utils/caseTransformation"
import { PaginatedData } from "types/root"
import { getFilterText } from "../utils"
import { Artist, ArtistsContextType, ArtistSocialLink } from "../types/artists"

const ArtistsContext = createContext<ArtistsContextType | null>(null)

export const ArtistsProvider = ({ children }: { children: ReactElement }) => {
  const [state, dispatch] = useReducer(artistsReducer, initialArtistsState)

  const fetchArtists = async (limit: number, offset: number, filter?: any, orderBy?: string) => {
    const response = await axios(
      `/artists/?limit=${limit}&offset=${offset}${getFilterText(filter)}${orderBy ? `&order_by=${orderBy}` : ""}`,
      { method: "get" }
    )

    dispatch({
      type: FETCH_ARTISTS,
      payload: { ...state, paginatedArtists: mapPaginatedDataToCamelCase<PaginatedData<Artist>>(response.data) }
    })
  }

  const fetchArtistSocialLinks = async (artistId: number) => {
    const response = await axios(
      `/artists/social_links/?artist_id=${artistId}`,
      { method: "get" }
    )

    dispatch({
      type: FETCH_ARTIST_SOCIAL_LINKS,
      payload: { ...state, artistSocialLinks: mapToCamelCase<ArtistSocialLink>(response.data.result) }
    })
  }

  return <ArtistsContext.Provider
    value={{ ...state, fetchArtists, fetchArtistSocialLinks }}>{children}</ArtistsContext.Provider>
}

export default ArtistsContext
